<script>
    import { environment } from "./environment";
    import { onMount } from "svelte";

    export let shopId = "";
    
    let returnLabel = "";

    onMount(async () => {
        await getPlacements();
        updateAppLabels();
    });

    async function getPlacements() {
        const ls = localStorage.getItem("uturn-placements");
        const storageData = JSON.parse(ls == "" || ls == null ? "{}" : ls);
        let json;

        if (storageData.date && Date.now() - storageData.date < 300000) {
            json = storageData;
        } else {
            const response = await fetch(
                `${environment.shopGateUrl}/shop/placements/${shopId}`
            );
            json = await response.json();
            const storageObject = {
                ...json,
                date: Date.now(),
            };
            localStorage.setItem(
                "uturn-placements",
                JSON.stringify(storageObject)
            );
        }
        returnLabel = json?.data?.return_label;
        if (!json){
            getPlacements();
        }
    }

    function updateAppLabels(){
        var elements = document.getElementsByClassName("uturn-label");
        for (var i = 0; i < elements.length; ++i) {
          var item = elements[i];  
          item.innerHTML = returnLabel;
          }
    }
</script>
